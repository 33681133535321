<template>
  <div class="flex justify-center">
  <div v-if="!user.stripe_id">
    <div class="absolute w-full h-full top-0 left-0 flex flex-col items-center justify-between bg-gradient-to-tr from-teal-500 to-teal-300 dark:from-teal-700 dark:to-teal-500 z-50">
    <div class="relative bg-white dark:bg-gray-900 w-11/12 md:max-w-md mx-auto rounded-lg shadow-lg mt-24 dark:text-white py-4 px-6">
        <div v-if="!close" class="absolute top-0 right-0 cursor-pointer m-1" @click="close=true">
          <svg class="h-8 w-8 p-2 fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
        </div>
        <svg class="h-24 mb-3 w-full" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <circle fill="#38b2ac" cx="12" cy="12" r="12"/>
            <path fill="#FFF" fill-rule="nonzero" d="M17.57895 17.61404H13.6842l1.61404-7.57895-4.1579 7.57895H8.7544L7.9649 9.77193l-1.6842 7.8421H4.70174L7.07018 6.47369h4.47368l.54386 6.2807 3.36842-6.2807h4.50877z"/>
          </g>
        </svg>
        <template v-if="!close">
        <p>
        Du bist noch ein Schritt entfernt, damit du alle Vorteile von Matchheft nutzen kannst.
        </p>

        <ul class="list-disc my-6 text-sm">
          <li>Halte dein Kader und Betreuerstab aktuell</li>
          <li>Speichere dein Matchheft</li>
          <li>QR-Code zum Matchheft</li>
        </ul>
        <p>(Du wirst zu stripe.com umgeleitet um das Abo abzuschliessen)</p>
        <div class="flex flex-col mt-4">
          <div class="cursor-pointer flex items-center p-4 px-20 py-3 rounded bg-teal-500 hover:bg-teal-400 text-white transition duration-500 ease-in-out" @click="checkOutMonthly()">
            <span class="font-semibold w-full text-center">15.- CHF pro Monat</span>
          </div>

          <div class="cursor-pointer mt-2 flex items-center px-20 py-3 rounded bg-teal-500 hover:bg-teal-400 text-white transition duration-500 ease-in-out" @click="checkOutYearly()">
            <span class="font-semibold w-full text-center">150.- CHF pro Jahr</span>
          </div>
        </div>
        <div class="text-gray-500 text-sm text-center mt-3">Du kannst deine Premium-Mitgliedschaft jederzeit kündigen.</div>
      </template>
      <template v-else>
        <div class="flex flex-col justify-center items-center">
        <span class="text-3xl font-semibold mb-2">oooh...</span>
        <img src="https://media.giphy.com/media/3oEjI80DSa1grNPTDq/giphy.gif">
        </div>
        <p class="mt-6 mb-2 text-center">
          Du willst die Vorteile nicht nutzen? Das ist sehr schade. Fehlt etwas? Zu teuer? Bitte teile matchheft.ch doch mit warum du nicht dabei sein willst. Wir finden bestimmt eine Lösung! Danke ❤️
        </p>
        <textarea v-model="notes" class="my-4 border-0 appearance-none outline-none resize-none w-full rounded p-2 bg-gray-200 dark:bg-gray-700" rows="2" maxlength="255" placeholder="Ich will nicht dabei sein weil..."></textarea>
        <button class="w-full primary" @click="logout()">Abmelden</button>
      </template>
      </div>
    </div>
  </div>
  <template v-else>
        <div class="flex flex-col items-center">
          <div class="bg-white dark:text-white dark:bg-gray-900 my-5 rounded-md shadow p-3 mx-12 flex flex-col md:w-2/3">
          <div id="error-message"></div>
          <div class="flex flex-col items-center" v-if="!hasValidSubscription">
            <span class="font-semibold mb-2">ohh...</span>
            <p class="my-2 font-semibold text-red-600 bg-red-200 border border-red-400 p-2 rounded" v-html="$t('subscription_invalid_text')"></p>
            <img src="https://media.giphy.com/media/vQqeT3AYg8S5O/giphy.gif"/>
          </div>
          <span class="text-2xl">{{$t('account')}}</span>
          <div v-if="logo" class="flex justify-center w-full mb-3">
          <img class="object-contain w-24 dark:bg-white" :src="logo" alt="Logo">
          </div>
          <p class="my-4">
            {{$t('subscription_text')}}
          </p>
          <button class="primary" @click="manageSubscription()">{{$t('subscription_edit')}}</button>
          <span class="font-semibold mt-6 mb-2">{{$t('profil')}}</span>
          <form @submit.prevent="updateProfile()" class="dark:text-white">
          <span class="text-xs font-semibold">{{ $t('email') }}</span>
          <input v-model="email" class="mt-1 mb-2" type="email" name="email" :placeholder="$t('email')">
          <span class="text-xs mt-1 font-semibold">{{ $t('password_new') }} ({{$t('password_min')}})</span>
          <input v-model="password" class="mt-1 mb-2" type="password" name="password" :placeholder="$t('password_new')">
          <input v-model="password2" class="mt-1 mb-2" type="password" name="password_repeat" :placeholder="$t('password_repeat')">
          <button class="primary mt-2 mb-4 w-full" :class="[canUpdateProfile ? '' : 'cursor-not-allowed opacity-50']" :disabled="!canUpdateProfile" type="submit">Speichern</button>
        </form>
        </div>
        <Footer class="text-center"></Footer>
      </div>
  </template>
  <AlertSucceed :show="saved" :message="$t('profile_update_succeed')"></AlertSucceed>
</div>
</template>

<script>
import ApiClient from '@/assets/js/ApiClient.js'
import { mapGetters, mapActions } from 'vuex'
import Footer from '@/components/Footer'
import AlertSucceed from '@/components/AlertSucceed'
export default {
  components: {
    Footer,
    AlertSucceed
  },
  data () {
    return {
      close: false,
      notes: '',
      password: '',
      password2: '',
      email: '',
      errorMessage: null,
      saved: false
    }
  },
  computed: {
    ...mapGetters({ user: 'user', hasValidSubscription: 'hasValidSubscription', logo: 'logo' }),
    canUpdateProfile () {
      if (this.password !== '') {
        if (this.password !== this.password2) {
          return false
        }
        if (this.password.length < 6) {
          return false
        }
        return true
      }
      if (this.email !== this.user.email) {
        if (this.email === '') {
          return false
        }
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions(['logoutUser']),
    manageSubscription () {
      ApiClient.get('api/stripe/session').then(response => {
        window.location.replace(response.data)
      })
    },
    checkOutMonthly () {
      this.checkout(process.env.VUE_APP_STRIPE_PLAN_MONTHLY)
    },
    checkOutYearly () {
      this.checkout(process.env.VUE_APP_STRIPE_PLAN_YEARLY)
    },
    logout () {
      ApiClient.put('api/user', { notes: this.notes }).then(response => {
        this.notes = ''
        this.logoutUser()
      })
    },
    updateProfile () {
      var payload = {}
      if (this.password !== '') {
        payload.password = this.password
      }
      if (this.email !== this.user.email) {
        payload.email = this.email
      }
      ApiClient.put('api/user', payload).then(response => {
        this.$store.commit('setUser', response.data.user)
        this.password = ''
        this.password2 = ''
        this.saved = true
        setTimeout(async () => {
          this.saved = false
        }, 2000)
      }).catch((error) => {
        if (Object.keys(error.response.data.error).some(e => e === 'email')) {
          alert(this.$t('email_exists'))
        } else {
          alert(JSON.stringify(error.response.data.error))
        }
      })
    },
    checkout (stripePrice) {
      var stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK)

      stripe.redirectToCheckout({
        lineItems: [{ price: stripePrice, quantity: 1 }],
        mode: 'subscription',
        successUrl: process.env.VUE_APP_BASE_URL + '?stripe=1',
        cancelUrl: process.env.VUE_APP_BASE_URL + '/account',
        customerEmail: this.user.email
      })
        .then(function (result) {
          if (result.error) {
            var displayError = document.getElementById('error-message')
            displayError.textContent = result.error.message
          }
          alert(result.error.message)
        })
    }
  },
  mounted () {
    this.email = this.user.email
  }
}

</script>
